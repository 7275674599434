import React, { useEffect, useState } from "react";
import { graphql } from "gatsby";
import Layout from "@components/layout";
import { PageMeta } from "@components/pageMeta";
import IntroBlock from "@components/other/introBlock";
import { motion } from "framer-motion";
import ModuleZone from "~moduleZone";

const Testimonials = ({ preview = false, data, previewDoc }) => {
  const page = preview ? previewDoc : data?.page;
  const { title, subtitle, seo, modules } = page || {};
  const [reviewData, setReviewData] = useState([]);

  useEffect(() => {
    const getReviews = async () => {
      const response = await fetch("/.netlify/functions/handle-reviews");
      const data = await response.json();

      setReviewData(data);
    };
    getReviews();
  }, []);

  // filter through reviewData?.data?.data and filter out results that have no comment
  const filteredReviews = reviewData?.data?.data.filter(
    (review) => review.comment !== ""
  );

  return (
    <Layout reversedHeader={true}>
      <PageMeta {...seo} />
      <IntroBlock title={title} subtitle={subtitle} />
      {modules && <ModuleZone {...modules} />}
      <div className="md:columns-2 lg:columns-3  pt-space-m px-gutter lg:px-10 gap-x-[30px]">
        {filteredReviews?.map(({ deal__contact_person_c, comment }, index) => (
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ duration: 0.8 }}
            key={index}
            className="bg-white rounded-[50px]  px-[51px] py-[57px] w-full h-full mb-10 "
            style={{ breakInside: "avoid" }}
          >
            <h2 className="blockH2 mb-8">{deal__contact_person_c}</h2>
            <p className="richH2 text-grey">"{comment}"</p>
          </motion.div>
        ))}
      </div>
    </Layout>
  );
};

export default Testimonials;

export const pageQuery = graphql`
  query sanityTestimonialsPageQuery {
    page: sanityTestimonialsPage {
      title
      subtitle

      seo {
        ...SEO
      }
      modules {
        ...HomeModules
      }
    }
  }
`;
